import { useRolesQuery } from "@/graphql/types";
import { activeActivity } from "@/plugins/i18n";
import { computed } from "vue";

export const useRoles = () => {
  const { loading, result, onError } = useRolesQuery(() => ({
    activityId: activeActivity.value.id,
  }));

  onError((err) => console.log(err));

  const roles = computed(() => result.value?.roles || []);

  return { loading, roles };
};
