import { useWhoAmiQuery } from "@/graphql/types";
import { computed } from "vue";

const defaultUser = {
  id: 0,
  activation: 0,
  createdAt: undefined,
  email: "",
  image: "",
  language: 0,
  lastLoginAt: undefined,
  name: "",
  settings: [2, 1, 0, 1, 1, 0], // [layoutMode, color scheme, input style, ripple effect, menu theme, component themes]
};

export const useWhoAmi = () => {
  const { loading: IamLoading, result } = useWhoAmiQuery();

  const Iam = computed(() => result.value?.whoAmI || defaultUser);

  return { IamLoading, Iam };
};
