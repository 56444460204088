import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { isNumber } from "chart.js/helpers";
import { Responsible, useUserResponsibleQuery } from "@/graphql/types";
import { ActiveActivity, activeActivity } from "@/plugins/i18n";
import { setActiveActivity } from "@/graphql/session";
import { useWhoAmi } from "../user/useWhoAmi";

export const useUserResponsible = () => {
  const { currentRoute } = useRouter();
  const { Iam } = useWhoAmi();
  const selected = ref<Responsible | undefined>(undefined);

  const userAccountId = computed(() => {
    const userId = currentRoute.value.params.userAccountId;
    return isNumber(userId) ? Number(userId) : null;
  });

  const { result, loading: loadResponsible } = useUserResponsibleQuery(() => ({
    input: {
      userAccountId: userAccountId.value,
    },
  }));

  const activityId = currentRoute.value.params.activityId;
  const sId =
    (isNumber(activityId) ? Number(activityId) : 0) ||
    selected.value?.activity?.id ||
    activeActivity.value.id;

  const responsible = computed(() => {
    const resp = result.value?.userResponsible || [];
    if (resp?.length) {
      const find = resp.find((item) => item.activity.id === sId); // || resp[0];
      if (find) {
        selected.value = { ...find } as Responsible;
        /**detect if activeActivity changes from route**/
        setTimeout(() => {
          setActiveActivity({
            ...find.activity,
            isOwner: find.activity.ownerId === Iam.value?.id,
            access: find.role?.access || [],
          } as ActiveActivity);
        }, 900);
      }
    } else selected.value = undefined;
    return resp;
  });

  function setSelected(val: Responsible) {
    if (val) selected.value = val;
  }

  return { loadResponsible, responsible, selected, setSelected };
};
