import { MutationCreateActivityArgs, Responsible } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { RESPONSIBLE_FIELDS } from "@/graphql/responsible/responsible.sdl";
import { ACTIVITY_FIELDS } from "@/graphql/activity/activity.sdl";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { SUBSCRIPTION_PAYMENT_FIELDS } from "@/graphql/subscription/subscription.payment";

type CreateActivityData = {
  createActivity: Responsible;
};
const CREATE_ACTIVITY = gql`
    mutation CreateActivity($image: Upload, $input: CreateActivityInput!) {
        createActivity(image: $image, input: $input){
            ${RESPONSIBLE_FIELDS}
            activity {
               ${ACTIVITY_FIELDS}
              payments{
                ${SUBSCRIPTION_PAYMENT_FIELDS}
              }
            }
        }
    }
`;

export const useCreateActivity = () => {
  const caDialog = ref<boolean>(false);
  const toast = useToast();
  const { t } = useI18n();
  const {
    loading: caLoading,
    mutate,
    onError,
    onDone,
  } = useMutation<CreateActivityData, MutationCreateActivityArgs>(
    CREATE_ACTIVITY,
    { context: { hasUpload: true } }
  );
  onDone(({ data }) => {
    const success = !!data?.createActivity;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("create.title"),
      detail: t(`create.${success ? "success" : "failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    caDialog.value = false;
  });

  function createActivity(input: any[]) {
    void mutate(
      { image: input[0], input: input[1] },
      {
        update: (cache, { data }) => {
          if (data?.createActivity) {
            cache.modify({
              fields: {
                userResponsible(existingRef: any, { toReference }) {
                  return [...existingRef, toReference(data.createActivity)];
                },
              },
            });
          }
        },
      }
    );
  }
  return { caLoading, caDialog, createActivity };
};
