export const ACTIVITY_FIELDS = `
  id
  name
  slogan
  address
  taxRate
  separator
  logo
  country
  decimalNumber
  currency
  currencySymbol
  ownerId
  createdAt
`;
